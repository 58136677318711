import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../components/layout';
import { Container, Row, Col } from 'react-bootstrap';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <Layout>
      <Container fluid="xl">
        <Row>
          <Col md={8}>
        
            <div className="form">
              <h1>Contact Us</h1>
              <form
                name="contact"
                method="post"
                action="/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out: <input name="bot-field" className="form-control" onChange={handleChange} />
                  </label>
                </p>

                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Name<br />
                        <input type="text" name="name" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label>Title<br />
                        <input type="text" name="title" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>
                </Row>

                <div className="form-group">
                  <label>Company<br />
                    <input type="text" name="company" onChange={handleChange} />
                  </label>
                </div>

                <div className="form-group">
                  <label>Address<br />
                    <input type="text" name="address" onChange={handleChange} />
                  </label>
                </div>

                <Row>
                  <Col md={4}>
                    <div className="form-group">
                      <label>City<br />
                        <input type="text" name="city" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>
                
                  <Col md={4}>
                    <div className="form-group">
                      <label>State<br />
                        <input type="text" name="state" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>

                  <Col md={4}>
                    <div className="form-group">
                      <label>Zip Code<br />
                        <input type="text" name="zip" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <div className="form-group">
                      <label>Telephone<br />
                        <input type="tel" name="telephone" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="form-group">
                      <label>Email Address<br />
                        <input type="text" name="email" onChange={handleChange} />
                      </label>
                    </div>
                  </Col>
                </Row>

                <div className="form-group">
                  <label>Website<br />
                    <input type="url" name="website" onChange={handleChange} />
                  </label>
                </div>



                <div className="form-group">
                  <legend>Reason for inquiry?</legend>

                  <div class="form-check">
                    <label>
                      <input type="checkbox" name="product-inquiry" value="Product Inquiry" class="form-check-input" /> Product Inquiry
                    </label>
                  </div>

                  <div class="form-check">
                    <label>
                      <input type="checkbox" name="become-a-member" value="Become A Member" class="form-check-input" /> Become A Member
                    </label>
                  </div>

                  <div class="form-check">
                    <label>
                      <input type="checkbox" name="become-fsc-certified" value="Become FSC Certified" class="form-check-input" /> Become FSC Certified
                    </label>
                  </div>
      
                </div>



                <div className="form-group">
                  <label>Comments
                    <textarea name="message" className="form-control" onChange={handleChange} />
                  </label>
                </div>

                <div className="form-group">
                  <button type="submit" className="button primary">Send</button>
                </div>

              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
